import React, { Fragment, useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '#home',
    },
    {
        id: 3,
        title: 'Services',
        link: '#services',
    },
    {
        id: 4,
        title: 'About',
        link: '#about',
    },
    {
        id: 5,
        title: 'Contact',
        link: '#contact',
    }
]

const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <ul className="main_menu_list clearfix">
            {menus.map((item, mn) => {
                return (
                    <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                        <a className="active"
                            href={item.link}>{item.title}</a>

                    </ListItem>
                )
            })}
        </ul>
    )
}

export default MobileMenu;